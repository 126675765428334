import React, { FC, Fragment, useContext } from 'react'
import Header from './Header'
import Footer from './Footer'
import UserContext from '../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import Navigation from './Navigation'
import { GlobalContext } from '../contexts'
import loading from '../icons/icon_loading.svg'
import AsCheckForm from '../components/AsCheckForm'

type Props = {
    title?: string
    active?: string
}
const Layout: FC<React.HTMLProps<any> & Props> = ({ children, title, active }) => {
    const { globalValue, fetchLoad } = useContext(GlobalContext)
    const { config } = globalValue
    const userContext = useContext(UserContext)
    const userInfo = userContext.user
    const navigate = useNavigate()
    const logoutClickHandle = () => {
        userContext.removeUser()
        navigate('/')
    }

    return (
        <Fragment>
            <Header />
            <div className='tool'>
                {userInfo.auth != 0 && (
                    <div className='sub-header'>
                        <span>
                            <b>{userInfo.name}</b> 님
                        </span>
                        <button type='button' onClick={logoutClickHandle}>
                            로그아웃
                        </button>
                    </div>
                )}
                {title && (
                    <div className='sub-title'>
                        <div>
                            <h3>{title}</h3>
                        </div>
                    </div>
                )}

                {userInfo.auth != 0 && <Navigation active={active} />}
            </div>
            <main>{children}</main>

            <div className='container row'>
                <div className='as-container image'>
                    <img src='/images/as_desktop.jpeg' className='desktop' />
                    <img src='/images/as_mobile.jpeg' className='mobile' />
                </div>
                <AsCheckForm />
            </div>
            <div className='contact'>
                <p>
                    <span>
                        고객센터 <a href={`tel:${config.contact}`}>{config.contact}</a>
                    </span>
                    <span>(09~18시 , 주말,공휴일제외)</span>
                </p>

                <p>
                    <span>공사•물품견적의뢰/업무제휴/홍보제안</span>&nbsp;
                    <span>
                        <a href={`mailto:${config.email}`}>{config.email}</a>
                    </span>
                </p>
            </div>

            <Footer />
            {fetchLoad && (
                <div className='loading-panel'>
                    <img src={loading} />
                </div>
            )}
        </Fragment>
    )
}

export default Layout
