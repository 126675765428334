import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../contexts'
type Banner = {
    url: string | null
    image: string
    imageMobile?: string
    alt: string
}

const partners: Banner[] = [
    { url: 'http://www.infra-k.net', image: '/images/partner_1.jpg', alt: '(주)인프라케이' },
    { url: null, image: '/images/partner_2.jpg', alt: '(주)코리아정보' },
    { url: 'http://www.greenitkr.com', image: '/images/partner_3.jpg', alt: '(주)그린아이티코리아' },
    { url: 'http://www.ist21.net', image: '/images/partner_4.jpg', alt: '(주)인포스텍' },
    { url: 'https://www.daraeparktech.com', image: '/images/partner_5.jpg', alt: '(주)다래파크텍' },
    { url: 'https://roadtok.com', image: '/images/partner_6.jpg', alt: '(주)ROADTOK' },
    { url: 'https://kr.canon/main', image: '/images/partner_7.jpg', alt: '캐논' },
    { url: 'http://www.supatent.com/', image: '/images/partner_8.jpg', alt: '특허법인 수' },
]
const partners2: Banner[] = [
    { url: 'https://www.customs.go.kr/jeju/main.do', image: '/images/partner_9.jpg', alt: '제주세관' },
    { url: 'https://taxstudy.nts.go.kr/frnt/login/login.do', image: '/images/partner_10.jpg', alt: '국세공무원교육원' },
    { url: 'http://www.sungsanposh.co.kr', image: '/images/partner_11.jpg', alt: '성산포수산업협동조합' },
]
const companies: Banner[] = [
    { url: null, image: '/images/company_2.jpg', alt: '미니장비' },
    { url: 'https://saekwang1181.modoo.at', image: '/images/company_1.jpg', alt: '세광종합기전' },
    { url: null, image: '/images/company_3.jpg', alt: '우리스카이', imageMobile: '/images/company_3_mobile.jpg' },
]
const Footer = () => {
    const globalContext = useContext(GlobalContext)
    const { config } = globalContext.globalValue
    return (
        <Fragment>
            <footer className='pc'>
                <div className='footer-top'>
                    <div className='content_1'>
                        <div className='title'>
                            <b>유지보수/ 유지관리•성능점검</b>업무계약
                        </div>
                        <ul>
                            {partners2.map((banner, index) => {
                                return (
                                    <li key={index}>
                                        {banner.url ? (
                                            <a href={banner.url} target='_blank'>
                                                <img src={banner.image} alt={banner.alt} />
                                            </a>
                                        ) : (
                                            <span>
                                                <img src={banner.image} alt={banner.alt} />
                                            </span>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div>
                        <img src='/images/service1_desktop.jpg' />
                        <img src='/images/service2_desktop.jpg' />
                    </div>
                    <div className='content_2'>
                        <div className='title'>
                            <b>정보통신설비 관급자재</b>
                            제주전지역 A/S접수처리 대행점
                        </div>
                        <dl>
                            <dd>대상기관</dd>
                            <dt>제주소재 관공서</dt>
                            <dd>대상설비</dd>
                            <dt>CCTV, 주차관제, 전관방송, 기타 등등, 관급구매 보유설비</dt>
                        </dl>
                        <p>(유지보수 계약업체 보유 및 하자보증기간 이전 설비는 제외)</p>
                        <div className='contact'>
                            <img src={require('../images/arrow.png')} />
                            <p>☎︎1899-6148</p>
                        </div>
                    </div>
                </div>
                <div className='partners'>
                    <ul>
                        {partners.map((partner, index) => {
                            return (
                                <li key={index}>
                                    {partner.url ? (
                                        <a href={partner.url} target='_blank'>
                                            <img src={partner.image} alt={partner.alt} />
                                        </a>
                                    ) : (
                                        <span>
                                            <img src={partner.image} alt={partner.alt} />
                                        </span>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='contents'>
                    <ul>
                        <li>
                            <Link to='/sub/privacy'>개인정보처리방침</Link>
                        </li>
                        <li>
                            <Link to='/sub/terms'>홈페이지이용약관</Link>
                        </li>
                        <li>
                            <Link to='/sub/refund'>환불정책안내</Link>
                        </li>
                    </ul>
                </div>
                <div className='company'>
                    <img src='/images/banners/infrak_black.jpeg' />
                    <div className='information'>
                        <p>통신판매업신고 제2024-제주외도-0037호 | 사업자등록번호 552-88-00867</p>
                        <p>회사 주식회사 인프라케이 | 대표이사 선무홍 | 주소 제주특별자치도 제주시 우령6길 16-11, 1층 101호</p>
                        <p>
                            전화 <a href={`tel:${config.contact}`}>{config.contact}(고객센터)</a> | 팩스 064-724-1042 | 이메일 <a href={`mailto:${config.email}`}>{config.email}</a>
                        </p>
                    </div>
                </div>
                <div className='copy'>Copyright &copy; 비투비제주 All rights reserved.</div>
                <div className='banners'>
                    <p className='description'>◼︎ 본 배너들은 B2BJEJU와 상관없으며, 직거래 하시면 됩니다.</p>
                    <ul>
                        {companies.map((company, index) => {
                            return (
                                <li key={index}>
                                    {company.url ? (
                                        <a href={company.url} target='_blank'>
                                            <img src={company.image} alt={company.alt} />
                                        </a>
                                    ) : (
                                        <p>
                                            <img src={company.image} alt={company.alt} />
                                        </p>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </footer>
            <footer className='mobile'>
                <div className='services'>
                    <img src='/images/service1_mobile.jpg' className='mobile' />
                    <img src='/images/service2_mobile.jpg' className='mobile' />
                </div>
                <div className='partners'>
                    <p>[협력사]</p>
                    <ul>
                        {partners.map((partner, index) => {
                            return (
                                <li key={index}>
                                    {partner.url ? (
                                        <a href={partner.url} target='_blank'>
                                            <img src={partner.image} alt={partner.alt} />
                                        </a>
                                    ) : (
                                        <span>
                                            <img src={partner.image} alt={partner.alt} />
                                        </span>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='partners_2'>
                    <p>[유지보수 / 유지관리•성능점검 업무계약]</p>
                    <ul>
                        {partners2.map((banner, index) => {
                            return (
                                <li key={index}>
                                    {banner.url ? (
                                        <a href={banner.url} target='_blank'>
                                            <img src={banner.image} alt={banner.alt} />
                                        </a>
                                    ) : (
                                        <span>
                                            <img src={banner.image} alt={banner.alt} />
                                        </span>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='conpanies'>
                    <p>아래의 업체는 B2BJEJU와 상관없으며, 직거래 하시면 됩니다.</p>
                    <ul>
                        {companies.map((company, index) => {
                            return (
                                <li key={index}>
                                    {company.url ? (
                                        <a href={company.url} target='_blank'>
                                            <img src={company.image} alt={company.alt} />
                                        </a>
                                    ) : (
                                        <span>
                                            <img src={company.imageMobile || company.image} alt={company.alt} />
                                        </span>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className='contents'>
                    <ul>
                        <li>
                            <Link to='/sub/privacy'>개인정보처리방침</Link>
                        </li>
                        <li>
                            <Link to='/sub/terms'>홈페이지이용약관</Link>
                        </li>
                        <li>
                            <Link to='/sub/refund'>환불정책안내</Link>
                        </li>
                    </ul>
                </div>

                <div className='company'>
                    <img src='/images/banners/infrak_black.jpeg' />
                    <div className='information'>
                        <p>통신판매업신고 제2024-제주외도-0037호</p>
                        <p>사업자등록번호 552-88-00867</p>
                        <p>회사 주식회사 인프라케이</p>
                        <p>대표이사 선무홍</p>
                        <p>주소 제주특별자치도 제주시 우령6길 16-11, 1층 101호</p>
                        <p>
                            전화 <a href={`tel:${config.contact}`}>{config.contact}(고객센터)</a>
                        </p>
                        <p>팩스 064-724-1042</p>
                        <p>
                            이메일 <a href={`mailto:${config.email}`}>{config.email}</a>
                        </p>
                    </div>
                </div>
                <div className='copy'>Copyright &copy; 비투비제주 All rights reserved.</div>
            </footer>
        </Fragment>
    )
}

export default Footer
